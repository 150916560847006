import * as React from 'react';
import {useEffect, useState} from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import DayScheduleTable from "../DayScheduleTable";
import WeekScheduleTable from "../WeekScheduleTable";
import axios from "axios";
import {Day, PoolInfo, Schedule} from "../Schedule";
import {useNavigate, useParams} from "react-router-dom";
import Grid from "@mui/material/Unstable_Grid2";
import PrimarySearchAppBar from "../componets/PrimarySearchAppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import UserFeedback from "../componets/UserFeedback";
import Footer from "../componets/Footer";
import Legend from "../componets/Legend";
import {Alert, AlertTitle, Link, Tooltip} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{padding: '2px'}}>
                    <div>{children}</div>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const ScheduleTable = () => {
    const {poolId} = useParams<{ poolId: string }>();
    const navigate = useNavigate();

    const date = new Date();
    const dayOfWeekStartedSunday = date.getDay();
    const dayOfWeek = dayOfWeekStartedSunday === 0 ? 6 : dayOfWeekStartedSunday - 1;

    const daysOfWeek: { [key: string]: string } = {
        'MONDAY': 'Pondělí',
        'TUESDAY': 'Úterý',
        'WEDNESDAY': 'Středa',
        'THURSDAY': 'Čtvrtek',
        'FRIDAY': 'Pátek',
        'SATURDAY': 'Sobota',
        'SUNDAY': 'Neděle',
        '': '    ',
    };

    const [value, setValue] = React.useState(dayOfWeek);
    const [poolName, setPoolName] = React.useState("Loading...");
    const [schedule, setSchedule] = React.useState<Schedule>();
    const [mainSchedule, setMainSchedule] = React.useState<Schedule>();
    const [showWeekArrows, setShowWeekArrows] = React.useState(true)
    const defaultPoolId = "aquacentrum-sutka"
    const [currentPoolId, setCurrentPoolId] = React.useState(poolId === undefined ? defaultPoolId : poolId);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const handlePoolChange = (poolId: string) => {
        setCurrentPoolId(poolId);
        navigate("pools/" + poolId);
    };

    const toggleBox = () => {
        setShowWeek(prevShowBox => !prevShowBox);
    };

    const onNextWeekChange = () => {
        toggleNextWeek(1, false);
    }

    const onPreviousWeekChange = () => {
        toggleNextWeek(-1, false);
    }

    const toggleNextWeek = (shiftWeekNumber: number, arround: boolean) => {
        if (!mainSchedule || !mainSchedule.schedules || mainSchedule.schedules?.length < 1) {
            return
        }

        let currentWeekTemp = currentWeek + shiftWeekNumber

        if (mainSchedule
            && mainSchedule.schedules
            && currentWeekTemp >= mainSchedule.schedules.length) {
            if (arround) {
                currentWeekTemp = 0
            } else {
                currentWeekTemp = mainSchedule.schedules.length - 1
            }
        }

        if (currentWeekTemp < 0) {
            if (arround) {
                currentWeekTemp = mainSchedule.schedules.length - 1
            } else {
                currentWeekTemp = 0
            }
        }
        setCurrentWeek(currentWeekTemp)
        if (mainSchedule && mainSchedule.schedules) {
            setSchedule(mainSchedule.schedules[currentWeekTemp])
            const poolInfoEmpty: PoolInfo = {
                name: '',
                length: 0,
                url: '',
                city: ''
            };
            let pollInfoObj: PoolInfo = poolInfoEmpty
            if (mainSchedule.schedules[currentWeekTemp].poolInfo !== undefined) {
                const poolInfoTemp = mainSchedule.schedules[currentWeekTemp].poolInfo
                if (poolInfoTemp === undefined) {
                    pollInfoObj = poolInfoEmpty
                } else {
                    pollInfoObj = poolInfoTemp
                }
            }
            setPoolName(pollInfoObj.name)
        }
    }

    const baseUrl: string = "https://starfish-app-tla65.ondigitalocean.app/schedule/"
    // const baseUrl: string = "http://localhost:8080/schedule/"

    const [valueFromChild, setValueFromChild] = useState("");

    const handleValueFromChild = (value: string) => {
        setValueFromChild(value);
    };
    const [showWeek, setShowWeek] = useState(true);
    const [currentWeek, setCurrentWeek] = useState(0)

    useEffect(() => {
        axios.get(baseUrl + currentPoolId)
            .then(response => {
                setMainSchedule(response.data)
                setShowWeekArrows(false)
                if (response.data.schedules && response.data.schedules.length > 0) {
                    setSchedule(response.data.schedules[0])
                    setPoolName(response.data.schedules[0].poolInfo.name)
                    if (response.data.schedules.length > 1) {
                        setShowWeekArrows(true)
                    }
                } else {
                    setSchedule(response.data);
                    setPoolName(response.data.poolInfo.name)
                    setShowWeekArrows(false)
                }
            });
    }, [currentPoolId]);

    useEffect(() => {
        if (mainSchedule && mainSchedule.schedules && mainSchedule.schedules.length > 0) {
            setSchedule(mainSchedule.schedules[currentWeek])
        }
    }, [currentWeek, mainSchedule]);

    return (
        <div style={{backgroundColor: "#eeeeee"}}>
            <PrimarySearchAppBar
                poolId={currentPoolId}
                onPoolChange={handlePoolChange}
                onShowWeekChange={toggleBox}
                onNextWeekChange={toggleNextWeek}
                showWeekArrows={showWeekArrows}
            />
            <Toolbar></Toolbar>

            <Grid id={"label-grid"} container justifyContent="center">
                <Grid xs={12} md={6}>
                    <Typography variant="h5" align="center" color={"#807f7f"} pb={0} pt={2}>
                        Obsazenost plaveckých drah veřejných bazénů
                    </Typography>
                    <Typography variant="h6" align="center" color={"#807f7f"} pb={0} pt={1}>
                        {poolName}
                    </Typography>
                </Grid>
            </Grid>

            {showWeekArrows &&
                <Grid container sx={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                    <Grid>
                        <Tooltip title={"Předchozí týden"} enterDelay={1000}>
                            <IconButton size="large" onClick={onPreviousWeekChange} color="primary">
                                <ArrowCircleLeftOutlinedIcon/>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={"Další týden"} enterDelay={1000}>
                            <IconButton size="large" onClick={onNextWeekChange} color="primary">
                                <ArrowCircleRightOutlinedIcon/>
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>
            }

            {schedule?.status != null && schedule.status >= 500 &&
                <Grid container>
                    <Grid xs={0} sm={2} md={3}></Grid>
                    <Grid xs={12} sm={8} md={6}>
                        <Alert severity="warning" style={{marginBottom: "20px"}}>
                            <AlertTitle>Stránka provozovatele bazénu není dostupná.</AlertTitle>
                            {schedule.poolInfo && <Link href={schedule.poolInfo.url}>
                                {schedule.poolInfo.url}
                            </Link>}
                        </Alert>
                    </Grid>
                </Grid>
            }

            {!showWeek &&
                <Grid id={"main-grid"} container spacing={1} sx={{paddingTop: "30px"}}>
                    <Grid xs={0} sm={2} md={3} xl={4}></Grid>
                    <Grid xs={12} sm={8} md={6} xl={4} id={"select-pool-grid"} paddingX={1}>
                        <Box sx={{
                            display: 'block',
                            backgroundColor: "#ffffff",
                            borderRadius: 3,
                            padding: 1
                        }} boxShadow={10}>
                            <Box sx={{borderBottom: 1, borderColor: 'divider', paddingBottom: 2}}>
                                {schedule?.week?.map((day: Day, index) => (
                                    <TabPanel value={value} index={index} key={index}>
                                        {daysOfWeek[day.name === undefined ? "" : day.name]} - {day.date}
                                        <DayScheduleTable day={day} onValueFromChild={handleValueFromChild}
                                                          rowToScrollId={valueFromChild} hours={schedule?.hours}/>
                                    </TabPanel>
                                ))}

                                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example"
                                      variant="scrollable">
                                    {schedule?.week?.map((day, index) => (
                                        <Tab
                                            label={daysOfWeek[day.name === undefined ? "" : day.name]?.substring(0, 2)} {...a11yProps(index)}
                                            style={{minWidth: '14%', maxWidth: '14%'}} key={index}/>
                                    ))}
                                </Tabs>
                            </Box>
                            <Legend/>
                        </Box>
                    </Grid>
                    <Grid xs={0} md={1} xl={2}></Grid>
                </Grid>
            }

            {showWeek &&
                <Grid id={"main-grid"} container spacing={1} sx={{paddingTop: "30px"}}>
                    <Grid xs={0} md={1} xl={2}></Grid>
                    <Grid xs={12} md={10} xl={8} id={"select-pool-grid"} paddingX={1}>
                        <Box sx={{
                            display: showWeek ? 'block' : 'none',
                            alignItems: 'center',
                            // justifyItems: 'center',
                            backgroundColor: "#ffffff",
                            borderRadius: 3
                        }}
                             boxShadow={10}>
                            <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                                <WeekScheduleTable week={schedule?.week}
                                                   onValueFromChild={handleValueFromChild}
                                                   rowToScrollId={valueFromChild}
                                                   hours={schedule?.hours}
                                                   popularTimes={schedule?.popularTimes}
                                />
                            </Box>
                        </Box>
                    </Grid>
                    <Grid xs={0} md={1} xl={2}></Grid>
                </Grid>
            }

            <UserFeedback poolId={poolId}/>

            <Footer/>

        </div>
    );
}

export default ScheduleTable;